import React from 'react';

import classNames from 'classnames';

import styles from './ArrowBigIcon.css';

type iconProps = {
    className?: string;
    leftOriented?: boolean;
};

export const ArrowBigIcon = React.memo((props: iconProps) => {
    return (
        <div
            className={classNames(styles.arrowIconContainer, {
                [styles.leftOriented]: props.leftOriented,
            })}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="64"
                height="64"
                viewBox="0 0 64 64"
                className={styles.arrowIcon}
            >
                <g fill="none" fillRule="evenodd">
                    <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                        d="M27 19L42 32.403 27 46"
                    />
                </g>
            </svg>
        </div>
    );
});
