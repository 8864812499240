import React from 'react';

import classNames from 'classnames';
import ResizeObserver from 'resize-observer-polyfill';
import Swiper from 'swiper';

import { MiscUtils } from '../../../utils/MiscUtils';
import { ArrowBigIcon } from '../Icons/Styleguide/ArrowBigIcon/ArrowBigIcon';
import { CircleArrowIcon } from '../Icons/Styleguide/CircleArrowIcon/CircleArrowIcon';
import styles from './SwipeSlider.css';
// TODO: i18n aria-label

const enum Directions {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
}

type SwipeSliderProps = {
    items: unknown[];
    renderItem: (item: unknown, index: number, pseudoTile?: boolean) => JSX.Element;
    className?: string;
    noShadows?: boolean;
    buttonClassName?: string;
    rightButtonClassName?: string;
    withPagination?: boolean;
    promoboxPosition?: string;
    isHomePageBanner?: boolean;
    pseudoTile?: boolean;
    isMobile?: boolean;
    variation?: string;
};

type SwipeSliderState = {
    activeSlide: number;
    hasLeftControl: boolean;
    hasRightControl: boolean;
};

let DIST_BETWEEN_ITEMS = 16;

export default class SwipeSlider extends React.PureComponent<SwipeSliderProps, SwipeSliderState> {
    readonly state = {
        activeSlide: 0,
        hasLeftControl: false,
        hasRightControl: false,
    };

    sliderRef = React.createRef<HTMLDivElement>();
    sliderContainerRef = React.createRef<HTMLDivElement>();

    ro: ResizeObserver | null = null;
    mySwiper: Swiper | null = null;

    componentDidMount() {
        DIST_BETWEEN_ITEMS = this.props.variation === 'C' ? 44 : 16;

        const el = this.sliderRef.current;
        const { isMobile, items } = this.props;

        if (el && !MiscUtils.isServer) {
            this.mySwiper = new Swiper(el, {
                watchSlidesProgress: true,
                speed: 400,
                slidesPerView: 'auto',
                spaceBetween: isMobile ? 0 : DIST_BETWEEN_ITEMS,
                slidesOffsetAfter: isMobile ? 0 : this.props.variation === 'C' ? 0 : DIST_BETWEEN_ITEMS,

                on: {
                    transitionEnd: () => {
                        if (this.mySwiper) {
                            const { isEnd } = this.mySwiper;
                            if (isEnd) {
                                // we need to force update active index for uncommon amount of items in views,
                                // like homepage banner on 1366px
                                this.mySwiper.activeIndex = items.length - 1;
                            }
                            this.setState({
                                hasLeftControl: !this.mySwiper.isBeginning,
                                activeSlide: this.mySwiper.activeIndex,
                                hasRightControl: !this.mySwiper.isEnd,
                            });
                        }
                    },
                    init: () => {
                        this.onResize(); // We need this on initialization to understand does swiper need nav arrows
                        this.addAttrs();
                    },
                    slideChange: this.addAttrs,
                    orientationchange: this.addAttrs,
                    update: this.addAttrs,
                    resize: this.onResize,
                },
            });
        }

        if (!MiscUtils.isServer) {
            window.addEventListener('resize', this.onResize);
        }

        // update slider on container size change (it happens when ads loads dynamically and push container)
        const sliderContainer = this.sliderContainerRef.current;
        if (sliderContainer && !MiscUtils.isServer) {
            this.ro = new ResizeObserver((entries) => {
                entries.forEach(this.mySwiper?.update);
            });
            this.ro.observe(sliderContainer);
        }

        this.onResize();
    }

    componentWillUnmount() {
        if (!MiscUtils.isServer) {
            window.removeEventListener('resize', this.onResize);
            if (this.mySwiper) {
                this.mySwiper.detachEvents();
                this.mySwiper.destroy(true, true);
            }
            if (this.ro) {
                this.ro.disconnect();
            }
        }
    }

    onResize = () => {
        this.setState({
            hasRightControl: !this.mySwiper?.isEnd,
        });
    };

    addAttrs() {
        const elements = document.querySelectorAll('.swiper-slide a');
        elements.forEach((element) => {
            if (element?.parentElement?.classList.contains('swiper-slide-visible')) {
                element.removeAttribute('aria-hidden');
                element.setAttribute('tabindex', '0');
            } else {
                element.setAttribute('aria-hidden', 'true');
                element.setAttribute('tabindex', '-1');
            }
        });
    }

    getItemsInViewportCount() {
        const el = this.sliderRef.current;
        if (el) {
            const items: HTMLElement[] = Array.from((el as HTMLElement).querySelectorAll(`.${styles.listItem}`));
            if (items.length === 0) {
                return 0;
            }
            const childWidth = items[0].getBoundingClientRect().width;
            return Math.max((el.clientWidth - DIST_BETWEEN_ITEMS) / (childWidth + DIST_BETWEEN_ITEMS), 1);
        }
        return 0;
    }

    onSliderClick(dir: Directions) {
        const count = this.getItemsInViewportCount();
        switch (dir) {
            case Directions.LEFT: {
                const index = this.state.activeSlide - count;
                this.mySwiper?.slideTo(index);
                break;
            }
            case Directions.RIGHT: {
                const index = this.state.activeSlide + count;
                this.mySwiper?.slideTo(index);
                break;
            }
        }
    }
    /** Usage index as a key doesn't cause any issues in this case.
     *  Because won't be sorted or edited by user.
     *  eslint-disable-next-line react/no-array-index-key
     */
    renderPagination() {
        if (!this.mySwiper) {
            return null;
        }
        return (
            <div className={styles.pagination}>
                {this.props.items.map((_, index) => (
                    <div
                        key={index.toString()}
                        onClick={() => this.mySwiper?.slideTo(index)}
                        role="button"
                        onKeyDown={(e) => {
                            if (e.key === 'ArrowRight') {
                                this.mySwiper?.slideTo(this.mySwiper?.activeIndex + 1);
                            } else if (e.key === 'ArrowLeft') {
                                this.mySwiper?.slideTo(this.mySwiper?.activeIndex - 1);
                            }
                        }}
                        tabIndex={0 - index}
                        aria-label="pagination bullet"
                        className={classNames(styles.bullet, {
                            [styles.bulletActive]: index === this.mySwiper?.activeIndex,
                        })}
                    />
                ))}
            </div>
        );
    }

    render() {
        const { hasLeftControl, hasRightControl } = this.state;
        const {
            promoboxPosition,
            noShadows,
            buttonClassName,
            items,
            renderItem,
            pseudoTile,
            rightButtonClassName,
            isHomePageBanner,
            withPagination,
            variation,
        } = this.props;

        return (
            <section
                data-variations={variation}
                ref={this.sliderContainerRef}
                className={classNames(styles.sliderContainer, {
                    [styles.hasLeftControl]: hasLeftControl,
                    [styles.hasRightControl]: hasRightControl,
                    [styles.promoSlider]: promoboxPosition === '0',
                    [styles.homePageBanner]: isHomePageBanner,
                })}
                aria-label="Carousel"
            >
                {!noShadows && (
                    <div
                        className={classNames(styles.leftShadow, {
                            [styles.leftShadowMobile]: hasRightControl,
                        })}
                    />
                )}
                <button
                    disabled={!hasLeftControl}
                    className={classNames(styles.toLeftButton, buttonClassName)}
                    onClick={() => this.onSliderClick(Directions.LEFT)}
                    data-element-description="slide-left"
                    aria-label="slide-button"
                >
                    {variation !== 'C' ? <ArrowBigIcon leftOriented /> : <CircleArrowIcon leftOriented />}
                </button>
                <div className={classNames('swiper', styles.swiperContainer)} ref={this.sliderRef}>
                    <div className="swiper-wrapper">
                        {items.map((item, index) => (
                            <div
                                // Usage index as a key doesn't cause any issues in this case.
                                // Because won't be sorted or edited by user.
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                className={classNames('swiper-slide', styles.listItem)}
                            >
                                {renderItem(item, index)}
                            </div>
                        ))}
                        {pseudoTile && (
                            <div key={-1} className={classNames('swiper-slide', styles.listItem, styles.pseudoTile)}>
                                {renderItem(items[0], -1, true)}
                            </div>
                        )}
                    </div>
                    {withPagination && this.renderPagination()}
                </div>
                <button
                    disabled={!hasRightControl}
                    className={classNames(styles.toRightButton, buttonClassName, rightButtonClassName)}
                    onClick={() => this.onSliderClick(Directions.RIGHT)}
                    data-element-description="slide-right"
                    aria-label="slide-button"
                >
                    {variation !== 'C' ? <ArrowBigIcon /> : <CircleArrowIcon />}
                </button>
                {!noShadows && <div className={styles.rightShadow} />}
            </section>
        );
    }
}
